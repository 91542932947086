import React, {useEffect} from "react";
import Hader from "../Hader";
import Futer from "../Futer";
// @ts-ignore
import back from "../../videos/back.webm";
// @ts-ignore
import backm from "../../videos/back.mp4";
import "../../styles/About.css"

function About(){

    return (
        <div>
            <Hader/>
            <div className="ContentAbout">
                <br/>
                <video controls width="400" height="300" autoPlay className="videoback">
                    <source src={backm} type='video/mp4'/>
                    <source src={back} type='video/webm'/>
                    Ваш браузер не поддерживает воспроизведение видео.
                </video>
                <br/>
                <br/>
                <div className="AboutAbz">
                    Торговая марка "GODINE” является относительно новым проектом для кофейного рынка России.
                    GODINE кофе явлется продолжением Эфиопской кофейной компании, которая занимается кофе с
                    2014г. В 2021г. было принято решение о создании собственной торговой марки обжаренного кофе,
                    а в 2022г. бренд был зарегистрирован.
                    Godine (Године) это фамилия основателей компании.
                    Не смотря на относительно небольшое время пребывания на рынке, кофе семьи Године продолжает
                    завоёвывать сердца клиентов.
                </div>
                <br/>
                <br/>
                <div className="AboutAbz ComissionerZitat">
                    "Имея собственные семейные фермы и станции обработки кофе в Эфиопии, мы знаем сколько усилий
                    вкладывается
                    на каждом этапе производства кофе. Поэтому мы ценим продукт, который мы создаём".
                </div>
                <br/>
                <div className="AboutAbz">
                    Ольга Године
                </div>
                <br/>
                <br/>
                <div className="AboutAbz ComissionerZitat">
                    "Эфиопия - это страна, где я родился, родина кофе. Россия - это мой дом.
                    Мне важно, чтобы обе эти страны развивались и жили достойно. Для меня кофе это вклад в развитие этих
                    двух стран.
                    Мы любим своё дело и стараемся, чтобы наш кофе был достойного качества".
                </div>
                <br/>
                <div className="AboutAbz">
                    Эшету Године
                </div>
                <br/>
                <br/>
                <br/>
                <br/>
                <div>
                    ИП Године Ольга Михайловна
                </div>
                <div>
                    Почтовый адрес: 156012, город Кострома, улица Костромская, д.112, корпус 2,кв.63
                </div>
                <div>
                    ИНН: 440116738509
                </div>
                <div>
                    ОГРНИП: 320440100014832
                </div>
            </div>
            <Futer/>
        </div>
    );
}

export default About;